<script setup lang="ts">
import { groupBy, isEmpty, orderBy } from 'lodash-es';
import { computed, toRef, useAsyncData, useFlightService, useConfigStore, useDevice } from '#imports';

const config = useConfigStore();
const device = useDevice();

const { data: flightFetchData, pending: flightFetchPending } = useAsyncData(
  'flight-recommendation',
  () =>
    useFlightService().getFlightHomepagePromos({
      country: config.country!,
      currency: config.currency!,
    }),
  {
    lazy: true,
    server: device.isCrawler,
    watch: [toRef(config, 'currency'), toRef(config, 'country')],
  },
);

const flightRecommendationsGroup = computed(() => {
  const flightResult = flightFetchData.value?.result;

  if (isEmpty(flightResult)) return;

  const groupedRecommendationsObj = groupBy(flightResult, 'arrAirport');

  const groupedRecommendations = orderBy(Object.values(groupedRecommendationsObj), 'length', 'desc');

  if (groupedRecommendations.length > 6) {
    groupedRecommendations[5] = groupedRecommendations[5]!.concat(groupedRecommendations.splice(6)).flat();
  } else if (groupedRecommendations.length < 6) {
    groupedRecommendations[0] = groupedRecommendations[0]!.concat(groupedRecommendations.splice(3)).flat();
  }

  return groupedRecommendations;
});
</script>

<template>
  <h2 class="text-extra font-bold mb-20">
    {{ $t('flight.flightrecommendation') }}
  </h2>

  <FlightRecommendationPlaceholder v-if="flightFetchPending" />

  <div
    v-else-if="!isEmpty(flightRecommendationsGroup)"
    class="grid grid-cols-3 gap-10"
  >
    <FlightRecommendationSlides
      v-for="(recommendations, index) in flightRecommendationsGroup"
      :key="index"
      :recommendations="recommendations"
    />
  </div>
</template>
